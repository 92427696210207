import { ClockCircleOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";

import Testimony from "../../screens/Testimony";
import { COMPANY_DATA, IMAGES } from "../../utils/constants/index";
import "./style.css";
// import MedicalCases from "../../screens/MedicalCases";

const Section1 = () => (
  <>
    <div className="section1">
      <Row>
        <Col flex="1 1 400px">
          <div className="img-text-container">
            <img
              alt="Cabinet dentaire Oudaya Dr Souhail El Meskine"
              src={IMAGES.section5[0]}
              style={{ width: "100%" }}
            />
            <div className="img-text">
              <span
                className="white-text bg-title"
                style={{
                  textShadow: "1px 1px 1px #cecdcd",
                }}
              >
                Centre dentaire Oudaya
              </span>
              <br />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col flex="1 1 400px" className="video-container">
          {/* <video controls width={0} height={0} className="video">
            <source src={""} type="video/mp4"></source>
            Sorry, your browser doesn't support embedded videos.
          </video> */}
          {/* <Col flex="auto" className="sub-section-1">
              <h2 className="white-text">
                <BookOutlined /> Formation
              </h2>
              <h3 className="white-text">
                • Doctorat en medecine dentaire
                <br /> • Diplome en orthodontie dentofaciale et orthopedie
                (spécialiste)
                <br />• Formation en esthetiqueet facettes dentaire, pédodontie,
                implantologie, parodontie et chirurgie buccale
              </h3>
            </Col> */}
          <Row>
            <Col flex="auto" className="sub-section-1">
              <span className="sub-sec-title bold">
                <ClockCircleOutlined />
                &nbsp; Horaires
              </span>
              <br />
              <span className="sub-sec-title">
                • Du <b>Lundi</b> à <b>Vendredi</b> A partir de <b>9h00</b>
                &nbsp; Jusqu'à <b>19h00</b>
              </span>
            </Col>
            <Col flex="auto" className="sub-section-1-black">
              <span className="sub-sec-title bold">
                <EnvironmentOutlined />
                &nbsp; Addresse
              </span>
              <br />
              <span className="sub-sec-title">• {COMPANY_DATA.ADDRESS}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Testimony />
      {/* <div style={{ width: "80%" , margin: "auto" }}>
        <MedicalCases show={true} />
      </div> */}
      <div className="gmap-container">
        <div className="gmap_canvas">
          <iframe
            width="100%"
            height="400px"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Dr%20Souhaila%20el%20mesekine%20temara&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div>
    </div>
    {/* <div className="pr-cr">
      <h2 style={{ color: "#f3f0db", textAlign: "center", fontWeight: "bold" }}>
        Présentation du centre CENTRE DENTAIRE OUDAYA
      </h2>
      • Notre centre dentaire se situe au niveau du nouveau quartier de&nbsp;
      <b>OULAD MTAA</b>&nbsp;,il est équipé du matériel de bonne qualité avec
      une diversification d’outils qui permet une prise en charge de différentes
      catégories de soins bucco dentaires • <b>Dr.Souhaila el meskine</b>, en
      plus de
      <b> son Doctorat en médecine dentaire </b>, a suivi plusieurs formations
      et cursus dans les différentes spécialités dentaires dont on site :
      <b>
        esthétique dentaire, parodontie, implantologie, prothèse, chirurgie
        orale et orthodontie.
      </b>
    </div> */}
    {/* <Row>
      <Col flex="auto" className="sub-section">
        <h1 className="white-text">
          <ReadOutlined /> &nbsp;Nos Prinicpes
        </h1>
        <p className="white-text">
          Notre pratique est fondée sur des principes solides permettant la
          reproductibilité des résultats ,la qualité des soins et le plus
          important la protection de la santé de nos patients et leur confort.
          C’est pour cela que le respect de la chaine d’asepsie et de la
          stérilisation est primordial, d’où :
          <br />• L’utilisation du matériel est strictement réservé aux
          instruments stérile et stérilisable
          <br />• L’emballage individuel de chaque instrument
          <br />• La désinfection et l’aeration du cabinet avant et après chaque
          patient
        </p>
      </Col>
      <Col flex="auto" className="sub-section">
        <h1 className="white-text">
          <ScheduleOutlined /> &nbsp; Nos Engagements
        </h1>
        <p className="white-text">
          DANS notre centre , la qualité est notre devoir. On s’engage à
          travailler avec des produits de haut qualité, un matériel technique de
          pointe et un service individualisés pour chaque patients, Ainsi, notre
          centre a investi dans du matériel de haute qualité, qui compte :
          <br />• Une installations et 3 futurs préinstallassions
          <br />• Une radio mural
          <br />• Un autoclave de stérilisation
          <br />• Un moteur d’endodontie + localisateur d’apex
          <br />• Un laser diode
          <br />• Diverses instruments manuels et de chirurgie
        </p>
      </Col>
      <Col flex="auto" className="sub-section">
        <h1 className="white-text">
          <PushpinOutlined />
          &nbsp; Nos Buts
        </h1>
        <p className="white-text">
          NOTRE CENTRE œuvre pour une « évidence based dentistry » on investit
          toujours dans la formation continue de toute l’équipe et ceci dans les
          différentes domaines, scientifique de communication et de matériel
          dentaire. Notre devise est la relation patient –praticien ,ou on
          choisit toujours de pratiquer dans un cadre de confiance et d’entente
          ou nos patients sont toujours écoutés, valorisés et pris en charge
        </p>
      </Col>
    </Row> */}
  </>
);

export default Section1;
