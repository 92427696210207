import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Footer from "./../src/components/footer/index";
import { GlobalLayout } from "./layouts/global";
import AboutUs from "./screens/AboutUs";
import Artciles from "./screens/Artciles";
import Centre from "./screens/Centre";
import Contact from "./screens/Contact";
import Equipe from "./screens/Equipe";
import Home from "./screens/Home";
import MedicalCases from "./screens/MedicalCases";

import "./App.css";

function App() {
  return (
    <>
      <Router>
        <GlobalLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="centre" element={<Centre />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="equipe" element={<Equipe />} />
            <Route path="contact" element={<Contact />} />
            <Route path="articles" element={<Artciles />} />
            {/* <Route path="cas-cliniques" element={<MedicalCases />} /> */}
          </Routes>
          <Footer />
        </GlobalLayout>
      </Router>
    </>
  );
}

export default App;

