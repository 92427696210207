import React from "react";

import Section1 from "../../components/section1";
import "./style.css";

const Home = () => {
  // const openNotification = (notificationType) => {
  //   navigator.clipboard.writeText(
  //     notificationType !== "mail"
  //       ? COMPANY_DATA.PHONE_NUMBER
  //       : COMPANY_DATA.EMAIL
  //   );
  //   notification.open({
  //     message: (
  //       <>
  //         {notificationType === "mail" && (
  //           <span className="info">
  //             <b>E-mail:</b>&nbsp; {COMPANY_DATA.EMAIL}
  //             <br />
  //             <CheckCircleOutlined /> bien copié
  //           </span>
  //         )}
  //         {notificationType === "whatsapp" && (
  //           <span className="info">
  //             <b>WhatsApp:</b>&nbsp; {COMPANY_DATA.PHONE_NUMBER}
  //             <br />
  //             <CheckCircleOutlined /> bien copié
  //           </span>
  //         )}
  //       </>
  //     ),
  //     style: NOTIFICATION_STYLE,
  //   });
  // };

  return (
    <>
      <div className="box-left">
        <Section1 />
      </div>
      <div className="center">
        <span
          className="sub-sec-title"
          style={{ color: "#f3f0db", fontWeight: "bold" }}
        ></span>
      </div>
    </>
  );
};

export default Home;
