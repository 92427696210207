import "./style.css";

const Testimony = () => {
  return (
    <div className="pr-cr">
      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>Témoignage</h2>
      <div className="outerdiv">
        <div className="innerdiv">
          <div className="div1 eachdiv">
            <div className="userdetails">
              <div className="imgbox">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT77oI8YXxTBV8ZkjbQb5IaLoW4yNhjZT2fQw"
                  alt=""
                />
              </div>
              <div className="detbox">
                <p className="name">Omar</p>
                <p className="designation">Patient</p>
              </div>
            </div>
            <div className="review">
              <h6>02 Janvier 2023</h6>
              <p>
                “ Docteur Souhaila et son équipe sont au top, très bon accueil,
                travail professionnel. Je suis allé faire une extraction de dent
                de sagesse, la médecin a été rapide et efficace, ma joue n'a pas
                enflé, tout s'est très bien passé, je recommande le centre
                fortement.”
              </p>
            </div>
          </div>
          <div className="div2 eachdiv">
            <div className="userdetails">
              <div className="imgbox">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT77oI8YXxTBV8ZkjbQb5IaLoW4yNhjZT2fQw"
                  alt=""
                />
              </div>
              <div className="detbox">
                <p className="name dark">Mohammed</p>
                <p className="designation" style={{ color: "black" }}>
                  Patient
                </p>
              </div>
            </div>
            <div className="review dark">
              <h6>16 Décembre 2022</h6>
              <p>
                “ Lors de mon rendez-vous chez Dr Souhaila ce matin, on m’a de
                nouveau rappelé ce qu’est une médecin vraiment compatissant et
                compétent, centré sur le patient. Elle est le genre de médecin
                que chaque personne devrait avoir la chance d’avoir dans son
                “coin de soins”. ”
              </p>
            </div>
          </div>
          <div className="div3 eachdiv">
            <div className="userdetails">
              <div className="imgbox">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZmw9hMzlMP3udTudTpoFYQ2459fM-1LLKuQ"
                  alt=""
                />
              </div>
              <div className="detbox">
                <p className="name ">Kenza</p>
                <p className="designation ">Patiente</p>
              </div>
            </div>
            <div className="review">
              <h6>02 Décembre 2022</h6>
              <p>
                “ Il est devenu très clair que le centre Dentaire Oudaya se
                distingue par sa méthode de pratique unique et le
                professionalisme de son stuff. Bonne continuation !”
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimony;
