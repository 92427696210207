import { Carousel, Col, Row } from "antd";
import { ReadOutlined, PushpinOutlined } from "@ant-design/icons";

import { IMAGES } from "../../utils/constants";
import "./style.css";

const Centre = () => {
  return (
    <>
      <div className="pr-cr thick-left-border">
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
          CENTRE DENTAIRE OUDAYA
        </h2>
        • Notre centre dentaire se situe au niveau du nouveau quartier de&nbsp;
        <b>OULAD MTAA</b>&nbsp;,il est équipé du matériel de bonne qualité avec
        une diversification d’outils qui permet une prise en charge de
        différentes catégories de soins bucco dentaires.
        <br />
        <b>• Dr. Souhaila el Meskine</b>, en plus de
        <b> son Doctorat en médecine dentaire, </b> a suivi plusieurs formations
        et cursus dans les différentes spécialités dentaires dont on site :
        <b>
          &nbsp; esthétique dentaire, parodontie, implantologie, prothèse,
          chirurgie orale et orthodontie.
        </b>
        <Row gutter={[16, 0]}>
          <Col flex="1 1 300px">
            <div className="pr-cr-carousel">
              <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
                Notre Cabinet
              </h2>
              <Carousel autoplay>
                {IMAGES.section4.map((e) => (
                  <img alt="" src={e} />
                ))}
              </Carousel>
            </div>
          </Col>
          <Col flex="1 1 300px">
            <Row gutter={[8, 8]}>
              <Col flex="1 1 250px">
                <div>
                  <h1>
                    <ReadOutlined /> &nbsp;Nos Prinicpes
                  </h1>
                  <p>
                    Notre pratique est fondée sur des principes solides
                    permettant la reproductibilité des résultats ,la qualité des
                    soins et le plus important la protection de la santé de nos
                    patients et leur confort. C’est pour cela que le respect de
                    la chaine d’asepsie et de la stérilisation est primordial,
                    d’où :
                    <br />• L’utilisation du matériel est strictement réservé
                    aux instruments stérile et stérilisable
                    <br />• L’emballage individuel de chaque instrument
                    <br />• La désinfection et l’aeration du cabinet avant et
                    après chaque patient
                  </p>
                </div>
              </Col>
              <Col flex="1 1 250px">
                <div>
                  <h1>
                    <PushpinOutlined />
                    &nbsp; Nos Buts
                  </h1>
                  <p>
                    NOTRE CENTRE œuvre pour une « évidence based dentistry » on
                    investit toujours dans la formation continue de toute
                    l’équipe et ceci dans les différentes domaines, scientifique
                    de communication et de matériel dentaire. Notre devise est
                    la relation patient –praticien ,ou on choisit toujours de
                    pratiquer dans un cadre de confiance et d’entente ou nos
                    patients sont toujours écoutés, valorisés et pris en charge
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Centre;
