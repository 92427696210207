import { Carousel } from "antd";

import { IMAGES } from "../../utils/constants";

const AboutUs = () => {
  return (
    <div className="pr-cr-carousel">
      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
        Assurer la qualité est notre ADN!
      </h2>
      <div>
        <Carousel autoplay>
          {IMAGES.section3.map((e) => (
            <img alt="" src={e} style={{ height: "2vh" }} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default AboutUs;
