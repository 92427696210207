const Artciles = () => {
  return (
    <div className="pr-cr">
      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
        Extrait d'artciles , écrit par Dr Souhaila El Meskine
      </h2>
      <iframe
        src="https://www.shifaa.ma/is-dental-treatment-during-pregnancy-safe.html"
        title="Dr El Meskine souhaila - Centre dentaire Oudaya - Témara  - Maroc"
        width={"100%"}
        height={"650px"}
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
};

export default Artciles;
