import React from "react";
import { Col, notification, Row } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  GoogleOutlined,
  CloseCircleOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import "./style.css";

import { COMPANY_DATA, COPYRIGHT } from "../../utils/constants";

const Footer = () => {
  const openNotification = (modalType) => {
    notification.open({
      message: (
        <>
          {modalType === "mail" && (
            <span className="info">
              <b>E-mail:</b>&nbsp; {COMPANY_DATA.EMAIL}
            </span>
          )}
          {modalType === "whatsapp" && (
            <span className="info">
              <b>WhatsApp:</b>&nbsp; {COMPANY_DATA.PHONE_NUMBER}
            </span>
          )}
        </>
      ),
      style: {
        backgroundColor: "#277e8e",
        fontFamily: "'abel', 'cursive'",
        fontSize: "20px",
        color: "#074c58",
      },
      closeIcon: <CloseCircleOutlined />,
    });
  };
  return (
    <>
      <div className="footer">
        Suivez nous sur les réseaux sociaux:
        <Row className="social-media-footer">
          <Col
            span={4}
            className="social-media-icon-top-wrapper"
            onClick={() => openNotification("mail")}
          >
            <GoogleOutlined className="social-media-icon" />
          </Col>
          <Col span={4} className="social-media-icon-top-wrapper">
            <a href={COMPANY_DATA.FACEBOOK}>
              <FacebookOutlined className="social-media-icon" />
            </a>
          </Col>
          <Col span={4} className="social-media-icon-top-wrapper">
            <a href={COMPANY_DATA.INSTAGRAM}>
              <InstagramOutlined className="social-media-icon" />
            </a>
          </Col>
          <Col
            span={4}
            className="social-media-icon-top-wrapper"
            onClick={() => openNotification("whatsapp")}
          >
            <WhatsAppOutlined className="social-media-icon" />
          </Col>
        </Row>
        <div className="footer-about">
          {/* <div>
            <span>Contactez nous sur : {COMPANY_DATA.PHONE_NUMBER}</span>
          </div>
          <div className="address">
            <EnvironmentOutlined /> <b>{COMPANY_DATA.ADDRESS}</b>
          </div> */}
          <div>
            <span className="copyright">
              {COPYRIGHT.COMPANY}
              <br /> {COPYRIGHT.YEAR} Copyright, All Rights Reserved. &#169;
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
