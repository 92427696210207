import { BackTop } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";

import Header from "../../components/header";

export const GlobalLayout = (props) => {
  return (
    <>
      <Header />
      <div className="backToTopLayer">
        <BackTop>
          <div className="backToTop">
            <UpCircleOutlined className="backToTopArrow" />
          </div>
        </BackTop>
      </div>
      <div className="container">{props.children}</div>
    </>
  );
};
