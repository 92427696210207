import { Card, Carousel, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";

import { IMAGES, TEAM_PICS } from "../../utils/constants";
import "./style.css";

const Equipe = () => {
  return (
    <>
      <div
        className="pr-cr-carousel thick-left-border"
        style={{ marginBottom: "10px" }}
      >
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
          Notre Equipe
        </h2>
        <Row justify="center">
          <Col style={{ margin: "auto", margin: "10px" }}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={
                <img alt="Dr Souhaila El Meskine" src={TEAM_PICS.doctor} />
              }
            >
              <Meta
                title="Dr Souhaila El Meskine"
                description="Médecin dentiste"
              />
            </Card>
          </Col>
          <Col style={{ margin: "auto", margin: "10px" }}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={<img alt="example" src={TEAM_PICS.assitant} />}
            >
              <Meta title="Ikram Zeroual" description="Assitante" />
            </Card>
          </Col>
        </Row>
      </div>
      <div className="pr-cr-carousel">
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
          Notre Equipe en plein action
        </h2>
        <div>
          <Carousel autoplay>
            {IMAGES.section2.map((e) => (
              <img alt="" src={e} style={{ height: "2vh" }} />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Equipe;
