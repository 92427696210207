import {
  EnvironmentOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  PhoneOutlined,
  ReadOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { Drawer, Menu } from "antd";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { COPYRIGHT, IMAGES } from "../../utils/constants/index";
import "./style.css";

const Header = () => {
  const navigate = useNavigate();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const show = () => {
    setIsDrawerVisible(true);
  };
  const hide = () => {
    setIsDrawerVisible(false);
  };
  return (
    <>
      <div className="top-hdr-container">
        <div
          style={{
            backgroundColor: "#1B9E9C",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
              fontSize: "11px",
            }}
          >
            Téléphone : +212 537 560 405 / +212 665 847 929
          </p>
        </div>
      </div>
      <div className="hdr-container">
        <img className="logo" src={IMAGES.logo} />
        <div className="navbar">
          <span>
            <MenuOutlined
              className="icon-bar"
              onClick={() => {
                show();
              }}
            />
          </span>
          <ul id="nav-lists">
            <li>
              <span
                className="menu-span"
                onClick={() => {
                  navigate("");
                }}
              >
                <HomeOutlined />
                &nbsp; Acceuil
              </span>
            </li>
            <li>
              <span
                className="menu-span"
                onClick={() => {
                  navigate("centre");
                }}
              >
                <EnvironmentOutlined />
                &nbsp; Centre
              </span>
            </li>
            <li>
              <span
                className="menu-span"
                onClick={() => {
                  navigate("equipe");
                }}
              >
                <TeamOutlined />
                &nbsp; Equipe
              </span>
            </li>
            {/* <li>
              <span
                className="menu-span"
                onClick={() => {
                  navigate("cas-cliniques");
                }}
              >
                <SmileOutlined />
                &nbsp; Cas Cliniques
              </span>
            </li> */}
            <li>
              <span
                className="menu-span"
                onClick={() => {
                  navigate("contact");
                }}
              >
                <PhoneOutlined />
                &nbsp; Contact
              </span>
            </li>
            <li>
              <span
                className="menu-span"
                onClick={() => {
                  navigate("aboutus");
                }}
              >
                <InfoCircleOutlined />
                &nbsp; About Us
              </span>
            </li>
            <li>
              <span
                className="menu-span"
                onClick={() => {
                  navigate("articles");
                }}
              >
                <ReadOutlined />
                &nbsp; Articles
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Drawer
        footer={
          <>
            <span className="copyright white-text">
              {COPYRIGHT.COMPANY}
              <br /> {COPYRIGHT.YEAR} Copyright, All Rights Reserved. &#169;
            </span>
          </>
        }
        footerStyle={{
          backgroundColor: "#012c32",
        }}
        bodyStyle={{
          backgroundImage: "#ecfffe",
        }}
        headerStyle={{
          display: "none",
        }}
        placement="right"
        closable={true}
        onClose={() => {
          hide();
        }}
        width={"70%"}
        visible={isDrawerVisible}
        style={{ position: "absolute" }}
      >
        <Menu
          style={{
            background: "transparent",
            border: "none",
            color: "#074d59",
            margin: "5px",
          }}
        >
          <Menu.Item
            key="1"
            icon={<HomeOutlined className="drawer-icon" />}
            onClick={() => {
              navigate("/");
            }}
          >
            &nbsp; Acceuil
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<EnvironmentOutlined className="drawer-icon" />}
            onClick={() => {
              navigate("centre");
            }}
          >
            &nbsp; Centre
          </Menu.Item>
          <Menu.Item
            key="3"
            icon={<TeamOutlined className="drawer-icon" />}
            onClick={() => {
              navigate("equipe");
            }}
          >
            &nbsp; Equipe
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<PhoneOutlined className="drawer-icon" />}
            onClick={() => {
              navigate("contact");
            }}
          >
            &nbsp; Contact
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<InfoCircleOutlined className="drawer-icon" />}
            onClick={() => {
              navigate("aboutus");
            }}
          >
            &nbsp; About Us
          </Menu.Item>
          <Menu.Item
            key="6"
            icon={<ReadOutlined className="drawer-icon" />}
            onClick={() => {
              navigate("articles");
            }}
          >
            &nbsp; Articles
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default Header;
