import { COMPANY_DATA } from "../../utils/constants";

const Contact = () => {
  return (
    <div className="pr-cr">
      <h2 className="white-text">Info & Contact</h2>
      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>Coordonnées</h2>
      <ul>
        <li>
          <b>E-mail :</b> &nbsp;{COMPANY_DATA.EMAIL}
        </li>
        <li>
          <b>Téléphone :</b>&nbsp; {COMPANY_DATA.PHONE_NUMBER}
        </li>
        <li>
          <b>Addresse :</b>&nbsp; {COMPANY_DATA.ADDRESS}
        </li>
      </ul>
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="100%"
            height="400px"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Dr%20Souhaila%20el%20mesekine%20temara&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
