const bg1 = "https://i.ibb.co/DR9YRTd/OFFICIAL-BG.jpg";
const bg2 = "https://i.ibb.co/N1Tjm88/DR-EL-MESKINE-SOUHAILA-BG.jpg";
const bg3 = "https://i.ibb.co/wQ2LtFd/bg-act0.jpg";
const bg6 = "https://i.ibb.co/hKm5mcm/bg-act1.jpg";
const bg7 = "https://i.ibb.co/y64FdrB/bg-act2.jpg";
const bg8 = "https://i.ibb.co/rGYYJ1W/bg-act3.jpg";
const bg4 = "https://i.ibb.co/5579Y8F/bg-act4.jpg";
const bg5 = "https://i.ibb.co/yq5HsCg/bg-act5.jpg";
const bg9 = "https://i.ibb.co/cg2LhHt/bg-qua0.jpg";
const bg10 = "https://i.ibb.co/bNTvJWJ/bg-qua1.jpg";
const bg11 = "https://i.ibb.co/3N3jBzH/bg-qua2.jpg";
const bg12 = "https://i.ibb.co/b7sD7Sf/bg-qua3.jpg";
const bg13 = "https://i.ibb.co/6DXvrXF/bg-qua4.jpg";
const bg14 = "https://i.ibb.co/ZS296My/bg-qua5.jpg";
const bg15 = "https://i.ibb.co/Y0FQS9F/bg-qua6.jpg";
const bg16 = "https://i.ibb.co/R3C46Hs/bg-qua7.jpg";
const bg17 = "https://i.ibb.co/4RGzV6q/bg-qua8.jpg";
const bg19 = "https://i.ibb.co/pZy9wj4/bg-qua9.jpg";
const bg20 = "https://i.ibb.co/fD6JpWr/bg-qua11.jpg";
const bg21 = "https://i.ibb.co/1TwN3YV/bg-qua12.jpg";
const bg22 = "https://i.ibb.co/FDmbJBv/bg-qua13.jpg";
const logo = "https://i.ibb.co/XtVRLs5/LOGO.png";
const bg23 = "https://i.ibb.co/0rz6dHY/DR-EL-MESKINE-SOUHAILA-9.jpg";
const bg24 = "https://i.ibb.co/wY8RW3B/bg-qua10.jpg";
const bg25 = "https://i.ibb.co/CH98Zjx/CAS-MED0.jpg";
const bg26 = "https://i.ibb.co/vDwM2Fx/CAS-MED0-APR.jpg";
const bg27 = "https://i.ibb.co/wwW9Pwq/CAS-MED1.jpg";
const bg28 = "https://i.ibb.co/LRg9KWB/CAS-MED1-APR.jpg";

export const TEAM_PICS = {
  doctor: "https://i.ibb.co/ZKXqznN/DR-EL-MESKINE-SOUHAILA-89.jpg" ,
  assitant: "https://i.ibb.co/6txdvNm/DR-EL-MESKINE-SOUHAILA-90.jpg",
};

export const COMPANY_DATA = {
  PHONE_NUMBER: "+212 537 560 405 / +212 665 847 929",
  EMAIL: "souhailaelmeskine@gmail.com",
  FACEBOOK: "https://m.facebook.com/Dr.Souhailaelmeskine",
  INSTAGRAM: "https://m.facebook.com/Dr.Souhailaelmeskine",
  ADDRESS:
    "Rue 7 Appartement 2 ,2éme étage Secteur 3 Riad Ouled Mtaa, Témara , Maroc",
};

export const NOTIFICATION_STYLE = {
  backgroundColor: "#f3f0db",
  fontFamily: "'abel', 'cursive'",
  fontSize: "20px",
  color: "white",
};

export const IMAGES = {
  logo: logo,
  section1: [bg2],
  section2: [bg3, bg1, bg4, bg5, bg7, bg8, bg6],
  section3: [bg17, bg11, bg13, bg14, bg15, bg20, bg24], //
  section4: [bg19, bg10, bg9, bg12, bg16, bg21, bg22],
  section5: [bg23],
  medicalCases: [bg25, bg26, bg27, bg28],
};

export const COPYRIGHT = {
  YEAR: new Date().getFullYear(),
  COMPANY: "Centre Dentaire Oudaya",
};
